@font-face {
  font-weight: 700;
  font-family: 'Graphik LCG Web';
  font-style: normal;
  font-stretch: normal;
  src:
    url('https://static.gofitify.com/fonts/graphik/Graphik-Bold-Cy-Gr-Web.woff2')
      format('woff2'),
    url('https://static.gofitify.com/fonts/graphik/Graphik-Bold-Cy-Gr-Web.woff')
      format('woff');
}

@font-face {
  font-weight: 600;
  font-family: 'Graphik LCG Web';
  font-style: normal;
  font-stretch: normal;
  src:
    url('https://static.gofitify.com/fonts/graphik/Graphik-Semibold-Cy-Gr-Web.woff2')
      format('woff2'),
    url('https://static.gofitify.com/fonts/graphik/Graphik-Semibold-Cy-Gr-Web.woff')
      format('woff');
}

@font-face {
  font-weight: 500;
  font-family: 'Graphik LCG Web';
  font-style: normal;
  font-stretch: normal;
  src:
    url('https://static.gofitify.com/fonts/graphik/Graphik-Medium-Cy-Gr-Web.woff2')
      format('woff2'),
    url('https://static.gofitify.com/fonts/graphik/Graphik-Medium-Cy-Gr-Web.woff')
      format('woff');
}

@font-face {
  font-weight: 400;
  font-family: 'Graphik LCG Web';
  font-style: normal;
  font-stretch: normal;
  src:
    url('https://static.gofitify.com/fonts/graphik/Graphik-Regular-Cy-Gr-Web.woff2')
      format('woff2'),
    url('https://static.gofitify.com/fonts/graphik/Graphik-Regular-Cy-Gr-Web.woff')
      format('woff');
}
